import React from "react"
import SocialLogo from "social-logos"

const SocialMedia = () => (
    <div className="widget widget_wpcom_social_media_icons_widget">
        <div className="widget-content">
            <h2 className="widgettitle">Social</h2>
            <ul>
                <li><a href="https://twitter.com/Paula_Galloway" target="_blank"><SocialLogo icon="twitter" /><span className="screen-reader-text">View Paula Galloway's profile on Twitter</span></a></li>
                <li><a href="https://www.instagram.com/pspec/" target="_blank"><SocialLogo icon="instagram" /><span className="screen-reader-text">View Paula Galloway's profile on Instagram</span></a></li>
                <li><a href="https://www.facebook.com/paula.galloway.370/" target="_blank"><SocialLogo icon="facebook" /><span className="screen-reader-text">View Paula Galloway's profile on Facebook</span></a></li>
                <li><a href="https://www.linkedin.com/in/paula-galloway-3aaa917a/" target="_blank"><SocialLogo icon="linkedin" /><span className="screen-reader-text">View Paula Galloway's profile on LinkedIn</span></a></li>
            </ul>
        </div>
        <div className="clear"></div>
    </div>
)
export default SocialMedia
