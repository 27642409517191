/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import Header from "./header"
import Footer from "./footer"
import "./zeeBusiness.css"
import "./zeeBusiness-additional.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <body className="home blog logged-in custom-background customize-support"></body>
        </Helmet>
        <div id="wrapper">
          <Header siteMetadata={data.site.siteMetadata} />
          <main>{children}</main>
          <Footer siteMetadata={data.site.siteMetadata} />
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
