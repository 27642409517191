import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import headerImage from '../images/56475-1280x8001.jpg'

const Header = ({ siteMetadata }) => (
    <div>
      <div id="header">
        <div id="head">
          <div id="logo">
            <Link to="/">
              <h1>{siteMetadata.title}</h1>
            </Link>
          </div>
        </div>
      </div>
      <div id="custom_header">
        <img src={headerImage} width="900" height="275" />
      </div>
      <div className="clear"></div>
    </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
