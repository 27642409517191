import { Link } from "gatsby"
import React from "react"

const Footer = ({ siteMetadata }) => (
    <footer>
        <div id="footer">
                <span className="credits-left">&copy; {new Date().getFullYear()} <Link to={'/'}>{siteMetadata.author}</Link></span>
                <span className="credits-right"><span>Theme by <a href="https://themezee.com">ThemeZee</a>, Built with <a href="https://gatsbyjs.org">Gatsby</a></span></span>
        </div>
        <div className="clear"></div>
    </footer>
)

export default Footer
